<template>
  <template v-if="windowActive && modalActive && targetIndex > -1">

    <MobileLearnCms
        v-if="modalActive === 'cms'"
        :cms="targetAdded"
        :item="targetItem"
        :memory-limit="7"
        @renewEnd="getLearnObjectResult"
        :use-cold-call="params.coldCallUseYn === 'Y'"
        :lrn-psblt-hr-div="params.lrnPsbltHrDiv"
    />

  </template>

  <template v-else>
    <main class="kb-main" id="kb-hrdcloud">
        <LxpMobileHeader title="「Value-up 리마인드」 과정">
          <template v-slot:left>
            <div class="util util-back">
              <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
                <i class="icon-history-back"></i>
              </a>
            </div>
          </template>
          <template v-slot:right>

          </template>
        </LxpMobileHeader>

      <div class="main-content main-component">
        <template v-if="tabMenu <= 0">

          <div class="hrdcloud-view-container " >
            <div class="kb-main" >
              <nav class="kb-nav-round-tabs"  style=" border-bottom: none;margin:10px;padding:0px;">
                <ul class="kb-nav-list">
                  <li class="kb-nav-item">
                    <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu=='main' }" @click="menu='main'"><span class="kb-nav-text">1.KB in the Movie</span></a>
                  </li>
                  <li class="kb-nav-item">
                    <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu=='0' }" @click="menu='0'"><span class="kb-nav-text">2.KB가치체계의 이해</span></a>
                  </li>
                  <li class="kb-nav-item">
                    <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu=='1' }" @click="menu='1'"><span class="kb-nav-text">3.추천 콘텐츠</span></a>
                  </li>
                </ul>
              </nav>
              <div v-if="menu=='main'" class="content-section " style="padding-top:15px;">
                <div class=" section-banner" style="padding-bottom:20px;" >
                  <div class="banner-background">

                    <a href="javascript:" @click="openLearnWindow(0, 'N')">
                      <div >
                        <CommonLazyImage :img-src="`https://lxpcms.kbstar.com/contents/common/banner_pc.jpg`"  />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="section-my ">
                  <div class="info-content">
                      <h6  class="name">[수료기준 : 1 + 2 + 3 을 모두 충족 시 수료]</h6>
                      <h4 class="team"></h4>
                      <h4 class="link">1.[KB in the Movie]: 추천영화 시청 + 감상평 작성</h4>
                       <h4 class="link">2.[KB가치체계]:2개 콘텐츠 학습완료</h4>
                        <h4 class="link">3.[추천 콘텐츠]:7개 이상 콘텐츠 학습완료</h4>
                        <span class="link"> &nbsp;① 소통/관계
                        <br> &nbsp;② 스트레스·건강관리
                        <br>&nbsp;③ 트렌드 insight
                        <br> &nbsp;④ 팀장 리더십  </span>
                  </div>

                  <div class="info-content mt-3 mb-3">
                    <h6 class="name" >[학습현황]</h6>
                    <h4 class="team"></h4>
                    <h4  v-show="postCnt!=null" class="link" >1. [KB in the Movie 감상평]  :  {{postCnt}} / 1</h4>
                    <h4  v-show="commonCnt!=null" class="link" >2. [KB가치체계] :  {{commonCnt}} / 2</h4>
                    <h4  v-show="recmdCnt!=null" class="link" >3. [추천 콘텐츠] : {{recmdCnt}} / 7</h4>
                  </div>


                </div>

                <div class="content-section section-divider">
                <MobileBoardReadOnly
                    :board-id="boardId[0]"
                />
                </div>
              </div>
              <div v-if="menu=='0'" class="content-section "  style="padding-top:15px;">
                <header class="section-header ">
                  <h3 class="title" >
                    {{ mainList[menu].title }}
                  </h3>
                </header>
                <HrdCloudList
                    :is-loading="isLoading"
                    :result="mainList[menu].items"
                    :session="session"
                    :show-put="true"
                    :paging="paging"
                    :paging-func="pagingFunc"
                />


              </div>
              <div v-if="menu=='1'" class="content-section "  style="padding-top:15px;">

                <nav class="kb-nav-round-tabs" style=" border-bottom: none;margin:0px;padding:0px;" >
                  <ul class="kb-nav-list">
                    <li class="kb-nav-item">
                      <a href="#" class="kb-nav-link" :class="{ 'is-active' : submenu==2 }" @click="submenu=2"><span class="kb-nav-text">①소통/관계</span></a>
                    </li>
                    <li class="kb-nav-item">
                      <a href="#" class="kb-nav-link" :class="{ 'is-active' : submenu==3 }" @click="submenu=3"><span class="kb-nav-text">②스트레스/건강관리</span></a>
                    </li>
                    <li class="kb-nav-item">
                      <a href="#" class="kb-nav-link" :class="{ 'is-active' : submenu==4 }" @click="submenu=4"><span class="kb-nav-text">③트렌드 insight </span></a>
                    </li>
                    <li class="kb-nav-item">
                      <a href="#" class="kb-nav-link" :class="{ 'is-active' : submenu==5 }" @click="submenu=5"><span class="kb-nav-text">④팀장 리더십</span></a>
                    </li>
                  </ul>
                </nav>
                <div v-if="submenu==2" class="content-section"  style="padding-top:15px;">
                  <header class="section-header ">
                    <h3 class="title" >
                      {{ mainList[submenu].title }}
                    </h3>
                  </header>
                  <HrdCloudList
                      :is-loading="isLoading"
                      :result="mainList[submenu].items"
                      :session="session"
                      :show-put="true"
                      :paging="paging"
                      :paging-func="pagingFunc"
                  />
                </div>
                <div v-if="submenu==3" class="content-section"  style="padding-top:15px;">
                  <header class="section-header ">
                    <h3 class="title" >
                      {{ mainList[submenu].title }}
                    </h3>
                  </header>
                  <HrdCloudList
                      :is-loading="isLoading"
                      :result="mainList[submenu].items"
                      :session="session"
                      :show-put="true"
                      :paging="paging"
                      :paging-func="pagingFunc"
                  />
                </div>
                <div v-if="submenu==4" class="content-section"  style="padding-top:15px;">
                  <header class="section-header ">
                    <h3 class="title" >
                      {{ mainList[submenu].title }}
                    </h3>
                  </header>
                  <HrdCloudList
                      :is-loading="isLoading"
                      :result="mainList[submenu].items"
                      :session="session"
                      :paging="paging"
                      :paging-func="pagingFunc"
                  />

                </div>
                <div v-if="submenu==5" class="content-section"  style="padding-top:15px;">
                  <header class="section-header ">
                    <h3 class="title" >
                      {{ mainList[submenu].title }}
                    </h3>
                  </header>
                  <HrdCloudList
                      :is-loading="isLoading"
                      :result="mainList[submenu].items"
                      :session="session"
                      :paging="paging"
                      :paging-func="pagingFunc"
                  />

                </div>

              </div>
            </div>


          </div>

        </template>

      </div>

    </main>

  </template>
</template>
<style scoped>
.section-banner .banner-background img,
.section-banner .banner-background video {width:100%;height:100%;object-fit:cover;object-position:center;}
.section-banner .swiper-slide {position:relative;padding-top:0!important;}
.section-banner .banner-background {position:relative;height:100%;width:100%;}
</style>
<script>
import {useStore} from 'vuex';
import {computed, onBeforeMount, reactive, ref, watch} from 'vue';
import {
  closeToggles,
  getDcdOptions, getFunc, getListFunc, pushQuery,
} from '@/assets/js/ui.init';
import {
  getItem, getItems,
  initProgressBar, isSuccess, lengthCheck, setPaging,
  timestampToDateFormat
} from '@/assets/js/util';
import {setMainItems} from '@/assets/js/modules/hrd/hrd-common';
import MobileLearnCms from '@/components/learn/mobile/window/MobileLearnCms';
import {
  banerExpsrLocCdDcds,
} from '@/assets/js/modules/baner/common-baner';
import {Pagination} from "swiper";
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import HrdCloudList from '@/components/hrdcloud/HrdCloudList';
import CommonLazyImage from '@/components/common/CommonLazyImage';
import {
  ACT_GET_HRDCLOUD_LIST
} from "@/store/modules/prep/prep";
import {useRoute, useRouter} from "vue-router";
import {
  ACT_GET_LEARN_OBJECT_ADDED, ACT_GET_LEARN_OBJECT_MASTER_LIST,
  ACT_GET_LEARN_OBJECT_RESULT,
  ACT_GET_LEARN_OBJECT_RESULT_LIST, ACT_GET_LEARN_RESULT_SUMUP, ACT_INSERT_LEARN_RESULT
} from "@/store/modules/learn/learn";
import {
  canDo,
  cmsTypes, collectiveTypes,
  enableLearn, fnshCateCdDcds, fnshCrtrCdDcds,
  isEvaluation,
  isProgress,
  isSurvey,
  lrnObjTyCds
} from "@/assets/js/modules/learn/learn-common";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_GET_EVL_SYNC_DT, ACT_INSERT_LRN_TY_EVL_DIST} from "@/store/modules/evaluation/evaluation";
import {ACT_GET_CRSE_DIST_APPLY, ACT_GET_CRSE_FNSH_DIST_LIST} from "@/store/modules/course/course";
import {entranceCloudNowOnCheck} from "@/assets/js/modules/cloud/cloud-common";
import MobileBoardReadOnly from "@/components/board/mobile/BoardReadOnly";
import {goBack} from '@/assets/js/modules/common/common';
import {getBoardCateList, getBoardMstInfo, getPostList} from "@/assets/js/modules/board/board-common";
// import bgImgUrl from "@/assets/lxp/images/main/bibi_cc.png";

export default {
  name: 'MobileRemindMain',
  components: {
    LxpMobileHeader,
    HrdCloudList,
    MobileBoardReadOnly,
    CommonLazyImage,
    MobileLearnCms,
  },
  setup() {

    initProgressBar();
    const route = useRoute();
    const router = useRouter();
    const windowActive = ref(false);
    const hrdLoading = ref(false);
    const posts = ref([]);
    const distCrseSn = computed(() => route.params.distCrseSn);
    const {showMessage} = useAlert();
    const isMobile = computed(() => store.state.auth.isMobile);
    const store = useStore();
    const lochref = ref(location.href);
    const bannerCode = ref(banerExpsrLocCdDcds.bpcc);
    const cloudMapModal = ref(false);
    // const bgImgUrl = require('@/assets/lxp/images/main/bibi_cc.png');
    const postPaging = ref({pageNo: 1, pageSize: 10 ,totalCount: 0});
    const items = ref([]);
    const learnObjects = ref([]);
    const finishes = ref([]);
    const applyModal = ref(false);
    const infoModes = [0, 1, 2];
    const confirmList = ref([]);
    const commonCnt = ref(null);
    const recmdCnt = ref(null);
    const postCnt = ref(null);
    const mainList = ref([
      { title: '(필수학습)KB가치체계의 이해\n\n', params: {category1:2 , category2: 1111, category3:1739, category4:1744}, isLoading: true, items:[] },
      { title: '(탭)\n', params: {}, isLoading: true, items:[] },
      { title: '(선택학습)①소통/관계\n\n', params: {category1:2 , category2: 1111,  category3:1739, category4:1740}, isLoading: true, items:[] },
      { title: '(선택학습)②스트레스/건강관리 \n\n', params: {category1:2 , category2: 1111,  category3:1739, category4:1741}, isLoading: true, items:[] },
      { title: '(선택학습)③트렌드 insight \n\n', params: {category1:2 , category2: 1111, category3:1739, category4:1742}, isLoading: true, items:[] },
      { title: '(선택학습)④팀장 리더십\n\n', params: {category1:2 , category2: 1111,category3:1739, category4:1743}, isLoading: true, items:[] },
    ]);
    const isLoading = ref(false);
    const boardId = reactive([
      'remind-review'
    ]);
    const targetItem = ref({});
    const targetAdded = ref({});

    const session = computed(() => store.state.auth.session);
    const sortTypeDcd = computed(() => getDcdOptions('2073'));



    const menu = ref(route.query.menu||'main');
    const submenu = ref(route.query.submenu||'0');
    const myLikeTotalCnt = ref('0');
    const PilItems = ref([]);
    const hrds = ref([]);
    const categories = ref([]);
    const hrdCloudFilter = ref(0);
    const boardObjects = ref([]);
    // const applyModal = ref(false);
    const learnSn = computed(() => parseInt(route.query.learn || 0));
    const paging = reactive({
      pageNo: 1,
      pageSize: 12,
      totalCount: 0
    });
    const isInit = ref(false);
    const tabMenu = ref(0);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1Toggle: false
    });
    const referenceObjects = ref([]);
    const referenceLearnObjectTypes = [
      lrnObjTyCds.Certificate,
      lrnObjTyCds.RefBoard,
      lrnObjTyCds.RefEvaluation,
      lrnObjTyCds.RefFinish,
      lrnObjTyCds.Custom,
      lrnObjTyCds.Book
    ];
    const boardTyCdDcd = ref('');
    const pledge = reactive({
      distCrseSn: '',
      lrnerId:'',
      lrnerNm:''
    });
    const boardLearnObjectTypes = [
      lrnObjTyCds.Board
    ];
    const exclusiveLearnObjectTypes = [
      lrnObjTyCds.SelectionSurvey
    ];
    const objectResults = ref([]);
    const modalActive = computed(() => route.query.modal);
    const targetIndex = ref(-1);
    const targetLearnType = computed(() => getLearnType(targetItem.value));
    const currentDate = new Date();

    const boardMst = ref({   });
    const boardCateList = ref([]);
    const  getBoardMst =  async () => {
      isLoading.value = true;


      getBoardMstInfo(boardId[0], boardMst, async () => {
        if (boardMst.value.postGrpNo) {
          // 글쓰기 버튼 숨김
          boardMst.value.postWrtYn = 'N';
        }
        getBoardCateList(boardMst.value.boardMstSn, boardCateList, true);

      });
    }
    const params = reactive({
      distCrseSn : 0,
      crseMstSn : 0,
      classCateSn : 0,
      classCateNm : '',
      mediaCateSn : 0,
      mediaCateNm : '',
      orgnzCdDcd : '',
      orgnzNm : '',
      prvdrSn : 0,
      prvdrNm : '',
      introDivCdDcd : '',
      introDivNm : '',
      crseNm : '',
      crseSumup : '',
      crseDtl : '',
      exptAblty : '',
      crseLvl : '',
      classUseYn : '',
      classCnt : 0,
      bgngDt : 0,
      endDt : 0,
      thumb : '',
      etcThumb : '',
      prevUrl : '',
      crseMlg : 0,
      totLrnPerid : 0,
      totLrnHr : 0,
      fnshCondYn : '', // 수료조건 여부
      rfltRateUseYn : '', // 반영 비율 사용 여부
      fnshCrtrUseYn : '', // 수료 기준 사용 여부
      fnshCrtrScore : 0, // 수료 기준 점수
      lrnTrgtGrpMstSn : 0, // 학습 그룹
      eduTrnctCdDcd : '', // 연수원 DCD
      eduTrnctNm : '', // 연수원
      loc : '', // 장소
      lrnTrgtGrpDtlSn: 0,
      mstThumb: '',
      coldCallUseYn: '',
      lrnPsbltHrDiv: '',
    });

    const resultParam = reactive({
      lrnMapngRsltSumupSn : 0,
      fnshYn : '',
      fnshPrcsRsnCd : '',
      getScore : 0,
      totProgRate : 0,
      rsltYn : '',
      getAblty : '',
      fnshTot: 0,
    });
    const windowOptions = reactive({
      answer: false
    });

    const initLearnMetaList = async () => {
      await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
      }).then(res => {
        if(isSuccess(res)){
          currentDate.value = new Date(res.srvSyncDt);
        }
      });

      await getFunc(`course/${ACT_GET_CRSE_DIST_APPLY}`, distCrseSn.value, params, () => {
        if(params.lrnTrgtGrpDtlSn > 0){
          store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_RESULT_LIST}`, distCrseSn.value).then(response => {
            if (lengthCheck(response)) {
              const items = getItems(response);
              // objectResults.value = items.filter(x => x.lrnObjTyCd !== lrnObjTyCds.SelectionSurvey);
              objectResults.value = items.filter(x =>
                  !referenceLearnObjectTypes.includes(x.lrnObjTyCd) &&
                  !boardLearnObjectTypes.includes(x.lrnObjTyCd) &&
                  !exclusiveLearnObjectTypes.includes(x.lrnObjTyCd)
              );

              referenceObjects.value = items.filter(x => referenceLearnObjectTypes.includes(x.lrnObjTyCd));
              boardObjects.value = items.filter(x => boardLearnObjectTypes.includes(x.lrnObjTyCd));

              if(learnSn.value > 0){
                const target = objectResults.value.map((x, i) => ({...x, objectIndex: i})).find(x => learnSn.value === x.lrnObjDtlDistSn);
                if(target) {
                  if (cmsTypes.includes(target.lrnObjTyCd)) {
                    if (enableLearn(params.lrnPsbltHrDiv)) openLearn({index: target.objectIndex, finishYn: target.fnshYn});
                  } else {
                    openLearn({index: target.objectIndex, finishYn: target.fnshYn});
                  }
                }
              }
            }
            isInit.value = true;
          });

          getFunc(`learn/${ACT_GET_LEARN_RESULT_SUMUP}`, distCrseSn.value, resultParam);
          getListFunc(`learn/${ACT_GET_LEARN_OBJECT_MASTER_LIST}`, null, learnObjects);
          getListFunc(`course/${ACT_GET_CRSE_FNSH_DIST_LIST}`, distCrseSn.value, finishes);

          getHrdCloudListConfirm();
        }else{
          showMessage({
            title: '학습정보없음',
            text: '입과되지 않는 연수입니다.'
          });
          router.push({name: 'TrainProceeding'})
        }
      });
    }
    const getRelObjects = (fnshCateCdDcd, result) => {
      const lrnObjTyCds = learnObjects.value.filter(x => x.fnshCateCdDcd === fnshCateCdDcd).map(x => x.lrnObjTyCd);
      return lrnObjTyCds.includes(result.lrnObjTyCd);
    }
    const getCurrentValue = (item) => {
      if (item === null || item === undefined) return 0;
      const useItems =  item.objects.filter(x => x.fnshCond === 'Y');
      if(useItems.length > 0){
        const percent = (useItems.filter(x => x.fnshYn === 'Y').map(x => (x.crtrValue || 0)).reduce(add, 0))/useItems.length;
        return percent >= 95 ? 100 : Math.ceil(percent);
      }
      return 0;
    }

    const statuses = computed(() => {
      const statusItems = [];
      finishes.value.forEach(f => {
        if(f.fnshCrtrCdDcd === fnshCrtrCdDcds.Normal){
          if(f.fnshCondValue > 0){
            if(f.fnshCateCdDcd === fnshCateCdDcds.Evaluation){
              statusItems.push({
                ...f,
                objects: objectResults.value.filter(r => getRelObjects(f.fnshCateCdDcd, r) && r.evlTyCdDcd === f.evlTyCdDcd)
              });
            }else{
              statusItems.push({
                ...f,
                objects: objectResults.value.filter(r => getRelObjects(f.fnshCateCdDcd, r))
              });
            }
          }
          if (f.fnshTot > 0) {
            if (f.fnshCateCdDcd === fnshCateCdDcds.Progress) {
              resultParam.fnshTot = f.fnshTot;
            }
          }
        }else if(f.fnshCrtrCdDcd === fnshCrtrCdDcds.Special){
          statusItems.push({
            ...f,
            objects: objectResults.value.filter(r => r.lrnObjTyCd === f.lrnObjTyCd)
          });
        }
      });
      return statusItems;
    });
    const openLearn = ({index, finishYn}) => {
      // 02 => 근무시간내
      // 03 => 근무시간외
      // cms 타입일 경우 학습 가능시간 정책 적용
      console.log(objectResults.value[index]);
      if (cmsTypes.includes(objectResults.value[index].lrnObjTyCd)) if (!enableLearn(params.lrnPsbltHrDiv, true)) return;

      if (objectResults.value[index].lrnObjTyCd === '100008' || objectResults.value[index].lrnObjTyCd === '100026' || objectResults.value[index].lrnObjTyCd === '100020') {
        enterNowClass(objectResults.value[index]);
      } else if (collectiveTypes.includes(objectResults.value[index].lrnObjTyCd)) {
        if (objectResults.value[index].svySubmitYn === 'Y') {
          openResultLearnWindow(index);
        } else {
          openLearnWindow(index);
        }
      } else if (isEvaluation(objectResults.value[index].lrnObjTyCd)) {
        if(finishYn === 'Y'){
          if (getShowAnsProc(objectResults.value[index])) {
            openResultLearnWindow(index);
          } else {
            showMessage(getShowAnsMessage(objectResults.value[index]));
          }
        }else {
          const basProgRate = objectResults.value[index].evlProgRate;
          const status = statuses.value.filter(x => x.fnshCateCdDcd === '2046001');
          if (basProgRate > 0 && status.length === 0) {
            showMessage('진도율 수료기준이 존재하지 않습니다.<br/>관리자에게 문의해 주세요');
            return false;
          } else {
            const progRate = getCurrentValue(status[0]);
            if (basProgRate > progRate) {
              showMessage(`평가가능 진도율을 충족하지 않습니다.<br/><br/><span class="text-primary">${progRate}%</span><span class="text"> / ${basProgRate}%</span>`);
              return false;
            }
          }
          let basTime = objectResults.value[index].evlBgngDt === null ? objectResults.value[index].objBgngDt : objectResults.value[index].evlBgngDt;
          if (currentDate.value.getTime() >= basTime) {
            if (objectResults.value[index].evlBgngCtrlYn === 'Y') {
              showMessage('관리자제어 중입니다.<br/>관리자에게 문의해주세요');
              return false;
            } else {
              openLearnWindow(index);
            }
          } else {
            showMessage('평가응시 가능시간이 아닙니다.');
          }
        }

      } else {
        if(finishYn === 'Y'){
          openResultLearnWindow(index);
        }else{
          openLearnWindow(index);
        }
      }
    }
    const getShowAnsProc = (item) => {
      const crctAnsRlsProc = item['crctAnsRlsProc'] ? item['crctAnsRlsProc'].split(',') : '';
      if (isNaN(parseInt(crctAnsRlsProc[1])) || isNaN(parseInt(crctAnsRlsProc[2]))) {
        return false;
      } else if (currentDate.value.getTime() >= (item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60)) &&
          currentDate.value.getTime() <= (crctAnsRlsProc[2] === '0' ? currentDate.value.getTime() :
              (item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60) +
                  (parseInt(crctAnsRlsProc[2]) * 1000 * 60)))) {
        return true;
      }
      return false;
    }
    const modalTitle = computed(() => {
      if (route.query.modal && targetItem.value && targetItem.value.lrnObjDtlDistSn > 0) {
        return targetItem.value.objNm;
      }
      return '학습홈';
    });

    const getShowAnsMessage = (item) => {
      const crctAnsRlsProc = item['crctAnsRlsProc'] ? item['crctAnsRlsProc'].split(',') : '';
      let msg = ''
      if (!getShowAnsProc(item)) {
        if (isNaN(parseInt(crctAnsRlsProc[1])) || isNaN(parseInt(crctAnsRlsProc[2]))) {
          msg = '답안지는 비공개 입니다.'
        } else {
          if (parseInt(crctAnsRlsProc[2]) === 0) {
            msg = '답안지 공개시간은<br>'.concat(timestampToDateFormat(item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60), 'yyyy.MM.dd hh:mm:ss'),' 이후부터 상시공개됩니다.');
          } else {
            msg = '답안지 공개기간은<br>'.concat(
                timestampToDateFormat(item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60), 'yyyy.MM.dd hh:mm:ss'), '~',
                timestampToDateFormat(item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60) + (parseInt(crctAnsRlsProc[2]) * 1000 * 60), 'yyyy.MM.dd hh:mm:ss'),'<br>기간동안만 공개됩니다.');
          }
        }
      }
      return msg;
    }
    const add = (accumulator, a) => {
      return accumulator + a;
    }
    const setLrnRsltSn = ({lrnRsltSn, index}) => {
      objectResults.value[index].lrnRsltSn = lrnRsltSn;
    }

    const setLrnTyEvlDistSn = ({lrnTyEvlDistSn, index}) => {
      objectResults.value[index].lrnTyEvlDistSn = lrnTyEvlDistSn;
    }
    const collectiveCnt = computed(() => objectResults.value.filter(x => collectiveTypes.includes(x.lrnObjTyCd)).length);
    const getDistObjParams = (target) => {
      const params = {lrnObjDtlDistSn: target.lrnObjDtlDistSn};
      if(isSurvey(target.lrnObjTyCd)){
        params.distObjSn = target.distSvySn;
        params.distObjNm = target.svyNm;
      }else if(isProgress(target.lrnObjTyCd)){
        params.distObjSn = target.objSn;
        params.distObjNm = target.objNm;
      }else if(isEvaluation(target.lrnObjTyCd)){
        params.distObjSn = target.lrnTyEvlDistSn;
        params.distObjNm = target.lrnEvlNm;
      }
      return params;
    }
    const openLearnWindow = async (index, callback) => {
      if(isLoading.value) return;
      isLoading.value = true;
      const target = objectResults.value[index];
      targetIndex.value = index;
      // 윈도우 옵션들 초기화
      Object.keys(windowOptions).forEach(k => {windowOptions[k] = false;})
      if (target && target.lrnTyEvlDistSn === 0 && target.lrnObjTyCd === '100005') {
        await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
        }).then(res => {
          if(isSuccess(res)){
            currentDate.value = new Date(res.srvSyncDt);
          }
        });
        await store.dispatch(`evaluation/${ACT_INSERT_LRN_TY_EVL_DIST}`, {
          distCrseSn: distCrseSn.value,
          lrnEvlMstSn: target.objSn
        }).then(res => {
          if(isSuccess(res)){
            setLrnTyEvlDistSn({lrnTyEvlDistSn : res.lrnTyEvlDistSn, index})
          }
        })
      }
      if(target && target.lrnObjDtlDistSn > 0){
        if(target.lrnRsltSn > 0){
          targetItem.value = target;
          getObjectAdded(callback);
        }else{
          await store.dispatch(`learn/${ACT_INSERT_LEARN_RESULT}`, getDistObjParams(target)).then(res => {
            if(isSuccess(res) && res.lrnRsltSn > 0){
              setLrnRsltSn({lrnRsltSn : res.lrnRsltSn, index})
              // 적용 확인...
              target.lrnRsltSn = res.lrnRsltSn;
              targetItem.value = target;
              getObjectAdded();
            }else{
              isLoading.value = false;
            }
          }).catch(e => {
            console.error(e);
            isLoading.value = false;
          });

        }
      }else{
        targetIndex.value = -1;
        isLoading.value = false;
      }
    }
    const openResultLearnWindow = (index) => {
      // 진도율의 경우 그대로 Cms 창을 오픈하다.
      if(isProgress(objectResults.value[index].lrnObjTyCd) && canDo(objectResults.value[index], currentDate.value)){
        openLearnWindow(index);
      }else if(isEvaluation(objectResults.value[index].lrnObjTyCd)){
        openLearnWindow(index, () => {windowOptions.answer = (targetAdded.value && targetAdded.value.evlScoreRlsYn === 'Y');});
      }else if(collectiveTypes.includes(objectResults.value[index].lrnObjTyCd) && objectResults.value[index].svySubmitYn === 'Y'){
        showMessage({
          title: '참여완료',
          text: '이미 설문에 참여하셨습니다.'
        });
      }else if(isSurvey(objectResults.value[index].lrnObjTyCd)){
        showMessage({
          title: '참여완료',
          text: '이미 설문에 참여하셨습니다.'
        });
      }
    }
    const reloadObject = () => {
      getLearnObjectResult();
    }
    const getLearnObjectResult = () => {
      if(targetIndex.value === -1) return;
      store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_RESULT}`, objectResults.value[targetIndex.value].lrnObjDtlDistSn).then(res => {
        if(lengthCheck(res)){
          objectResults.value[targetIndex.value] = getItem(res);
        }
      });
    }
    const goTrainMain = () => {
      router.push({name: 'TrainProceeding'});
    }
    const getObjectAdded = (callback) => {
      store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_ADDED}`, targetItem.value.lrnObjDtlDistSn).then(res => {
        if(lengthCheck(res)){
          // 학습 결과도 같이 넣어준다.
          const added = getItem(res);
          added.lrnRsltSn = targetItem.value.lrnRsltSn;
          targetAdded.value = added;
          if(callback instanceof Function) callback();

          windowActive.value = true;
          if (isMobile.value) router.push({query: {modal: targetLearnType.value}});

        } else {
          showMessage('학습자료가 없습니다.')
        }
        isLoading.value = false;
      }).catch(e => {
        console.error(e);
        isLoading.value = false;
      })
    }

    const getLearnType = (target) => {
      if(target && target.lrnObjDtlDistSn > 0){
        if(isProgress(target.lrnObjTyCd)){
          return 'cms';
        }else if(isEvaluation(target.lrnObjTyCd)){
          return 'evl';
        }else if(isSurvey(target.lrnObjTyCd)){
          return 'svy';
        }
      }
      return '';
    };

    const getHrdCloudListConfirm =  async () => {
      isLoading.value = true;
      // sortTyCdDcd=2073001&category1=2&pageNo=1&category2=1111&category3=1739
      store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`, {category1: 2, category2: 1111, category3: 1739, pageSize: 200}
      ).then(res => {
        confirmList.value = getItems(res);
        commonCnt.value = confirmList.value.filter(x => x.cmptnYn === 'Y' && x.hrdCloudCateSn === 1744).length;
        recmdCnt.value = confirmList.value.filter(x => x.cmptnYn === 'Y' && (x.hrdCloudCateSn === 1740 || x.hrdCloudCateSn === 1741 || x.hrdCloudCateSn === 1742 || x.hrdCloudCateSn === 1743)).length;
        // category1:2 , category2: 1111,  category3:1739, category4:1740
      });
      await getBoardMst();

      getPostList({boardMstSn: 12, lrnerId: session.value.lrnerId, pageNo: 1, pageSize: 10}, posts, postPaging, boardCateList, () => {
        postCnt.value = posts.value.length;
      });


      isLoading.value = false;
    };

    const getHrdCloudList = async (idx, hrdparams) => {
      isLoading.value = true;


      const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`, hrdparams);
      if (lengthCheck(res)) {
        setMainItems(mainList, res, idx);
        // hrds.value = getCheckItems(contentsList);
        paging.totalCount = res && res.paging ? res.paging.totalCount : 0;
        setPaging(paging, res)
      }
      isLoading.value = false;
    };

    const enterNowClass = (item) => {
      const params2 = {
        distCrseSn : params.distCrseSn,
        objDistSn: item.lrnObjDtlDistSn,
        lrnObjDtlDistSn: item.lrnObjDtlDistSn,
        objType: item.lrnObjTyCd,
        lrnerId: session.value.lrnerId,
        etnRefDtl: item.etnRefDtl
      }
      if (item.pledgeYn === "Y" && item.pledgeRsltYn != "Y") {
        pledge.distCrseSn = params.distCrseSn;
        pledge.lrnerId = session.value.lrnerId;
        pledge.lrnerNm = session.value.lrnerNm;
        applyModal.value = true;
      } else {
        entranceCloudNowOnCheck(params2);
      }
    }

    initLearnMetaList();

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      pushQuery({ pageNo:pageNo,menu:menu.value||'main', submenu:submenu.value||'0'});
      if (paging.pageNo != route.query.pageNo){
        getHrdCloudList(submenu.value, {...mainList.value[submenu.value].params,...paging});
      }

    };

    watch(() => submenu.value, async () => {
      if(submenu.value !=  (route.query.submenu||'0') && mainList.value[submenu.value]){
        pagingFunc(1);
        await getHrdCloudList(submenu.value, {...mainList.value[submenu.value].params,...paging});
      }
    });

    watch(() => menu.value, async () => {


      if(menu.value == 1){
        submenu.value = 2
        return;
      }else if(menu.value =='main'){
        pushQuery({ });
        getHrdCloudListConfirm();

        return;
      }else if (menu.value =='0'&& menu.value !=(route.query.menu||'1')){
        submenu.value = '';
        pushQuery({ pageNo:paging.pageNo,menu:menu.value||'main'});
        if(lengthCheck(mainList.value[menu.value])){
          await getHrdCloudList(menu.value, {...mainList.value[menu.value].params,...paging});
        }

        return;
      }
    });

    watch(() =>  route.query, async () => {
      if ( menu.value != (route.query.menu||'main'))  menu.value = route.query.menu||'main';
      if ( submenu.value != (route.query.submenu||'0'))     submenu.value = route.query.submenu||'0';
      if ( paging.pageNo != (route.query.pageNo||'1')) paging.pageNo = route.query.pageNo||'1';
    });

    onBeforeMount(  ()=>{
      if ( menu.value != (route.query.menu||'main'))  menu.value = route.query.menu||'main';
      if ( submenu.value != (route.query.submenu||'0'))     submenu.value = route.query.submenu||'0';
      if ( paging.pageNo != (route.query.pageNo||'1')) paging.pageNo = route.query.pageNo||'1';

      if (menu.value !='main' && menu.value != '1' ){
        getHrdCloudList(menu.value, {...mainList.value[menu.value].params,...paging});
      }else{
        submenu.value = route.query.submenu||'0';
        getHrdCloudList(submenu.value, {...mainList.value[submenu.value].params,...paging});
      }

    });





    return {
      isLoading, session, sortTypeDcd, hrds,   cloudMapModal, lochref,
      tabMenu, params, categories,
      mainList,
      toggles, closeToggles,
      modules: [Pagination],
      items,
      hrdCloudFilter,
      menu,
      myLikeTotalCnt,
      timestampToDateFormat,
      hrdLoading,
      bannerCode,
      PilItems,
      paging,
      pagingFunc,
      submenu,
      boardId,
      // bgImgUrl,
      getObjectAdded,
      infoModes,
      resultParam,
      objectResults,
      referenceObjects,
      boardObjects,
      collectiveCnt,
      statuses,
      setLrnRsltSn,
      getLearnObjectResult,
      targetIndex,
      targetItem,
      targetAdded,
      targetLearnType,
      windowActive,
      windowOptions,
      openLearnWindow,
      reloadObject,
      openLearn,
      goTrainMain,
      // 학습창 - 모바일
      modalActive,
      modalTitle,
      boardTyCdDcd,
      applyModal,
      pledge,
      commonCnt,
      recmdCnt,
      postCnt,
      goBack
    }
  }
};
</script>